import {
  Box,
  linkClasses,
  List,
  listClasses,
  ListItem,
  listItemClasses,
  styled,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { useEffect } from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';

import { Layout, TechnicalTimers } from '../../components';
import { allRoutes } from '../../constants/routes';

import {
  AircraftFilter,
  DateFilter,
  StatsAOGDR,
  StatsFR,
  StatsMTBA,
  StatsMTTR,
  StatsDowntime,
} from './components';
import { StatsProvider } from './components/StatsContext';
import { StatsUptime } from './components/statsUptime';

const PREFIX = 'TSMED-Stats';

const statsClasses = {
  dateGroup: `${PREFIX}-dateGroup`,
  header: `${PREFIX}-header`,
  tabs: `${PREFIX}-tabs`,
  tabsHeader: `${PREFIX}-tabsHeader`,
  pageTitle: `${PREFIX}-pageTitle`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints, palette } }) => ({
  display: 'flex',
  gap: spacing(4),
  padding: spacing(5, 2),
  flexDirection: 'column',
  [breakpoints.up('sm')]: {
    padding: spacing(5),
  },
  [`.${statsClasses.header}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    flexGrow: 1,
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: spacing(4),
    },
    [`.${statsClasses.pageTitle}`]: {
      flexGrow: 1,
    },
  },
  [`.${statsClasses.tabsHeader}`]: {
    [`.${listClasses.root}`]: {
      display: 'flex',
      gap: spacing(1.5),
      overflow: 'auto',
      scrollbarWidth: 'none',

      [`.${listItemClasses.root}`]: {
        padding: 0,
        width: 'auto',
        [`.${linkClasses.root}`]: {
          fontSize: '16px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          borderRadius: 10,
          padding: spacing(1.5, 2),
          background: `${palette.mapBlue[700]}80`,
          color: '#87888B',
          fontWeight: 500,
          textDecoration: 'none',
          [`&:hover`]: {
            color: '#ddd',
          },
          [`&.active`]: {
            color: '#ddd',
            background: '#364B5F',
          },
        },
      },
    },
  },
}));

const STATS_PATHS = {
  fr: 'fr',
  uptime: 'uptime',
  downtime: 'downtime',
  mttr: 'mttr',
  mtba: 'mtba',
  aogdr: 'aogdr',
};

export const StatsPageContent = () => (
  <Root>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography className={statsClasses.pageTitle} variant="h1">
        TMDX Aviation Maintenance System
      </Typography>
      <Box className={statsClasses.header}>
        <DateFilter />
        <AircraftFilter />
      </Box>

      <Box className={statsClasses.tabs}>
        <Box className={statsClasses.tabsHeader}>
          <List>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_FR} component={NavLink}>
                AOG Frequency
              </MuiLink>
            </ListItem>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_UPTIME} component={NavLink}>
                Uptime
              </MuiLink>
            </ListItem>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_DOWNTIME} component={NavLink}>
                Downtime
              </MuiLink>
            </ListItem>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_MTTR} component={NavLink}>
                MTTR & AOG Duration
              </MuiLink>
            </ListItem>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_MTBA} component={NavLink}>
                MTBA
              </MuiLink>
            </ListItem>
            <ListItem disableGutters>
              <MuiLink to={allRoutes.STATS_AOGDR} component={NavLink}>
                AOG Dispatch Reliability
              </MuiLink>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Routes>
        <Route element={<StatsFR />} path={STATS_PATHS.fr} />
        <Route element={<StatsUptime />} path={STATS_PATHS.uptime} />
        <Route element={<StatsDowntime />} path={STATS_PATHS.downtime} />
        <Route element={<StatsMTTR />} path={STATS_PATHS.mttr} />
        <Route element={<StatsMTBA />} path={STATS_PATHS.mtba} />
        <Route element={<StatsAOGDR />} path={STATS_PATHS.aogdr} />
        <Route path="*" element={<Navigate to={STATS_PATHS.fr} />} />
      </Routes>
    </LocalizationProvider>
  </Root>
);

export const StatsPage = () => {
  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

    if (link) {
      link.href = '/logo64.svg';
    }
  }, []);

  return (
    <Layout>
      <StatsProvider>
        <StatsPageContent />
        <TechnicalTimers />
      </StatsProvider>
    </Layout>
  );
};
