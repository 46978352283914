import { useCallback, useMemo } from 'react';

import {
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
  ORGAN_COLORS,
} from '../../../constants/const';
import { useElementRef } from '../../../hooks/useElementRef';
import { Case } from '../../../types/new';
import { CircleIcon } from '../../CircleIcon';
import styles from '../overlays.module.scss';

const ORGAN_TOP_OFFSET = -15;
const DEFAULT_Z_INDEX = 3;

interface Props extends Case {
  clientWidth: number;
  clientHeight: number;
  organTopOffsetIndex?: number;
  zIndex?: number;
}

export const CaseOrgan = ({
  id,
  organ,
  clientWidth,
  clientHeight,
  showOrganBorder,
  organTopOffsetIndex = 0,
  zIndex = 0,
  oscSubStatus,
}: Props) => {
  const { element: casePath } = useElementRef<SVGPathElement>(`${id}-curve`);

  const organColor = ORGAN_COLORS[organ.name];

  const getPosition = useCallback(
    (progressNumber: number) => {
      if (!casePath || !clientHeight || !clientWidth) {
        return { x: 0, y: 0 };
      }

      const pathLength = casePath.getTotalLength();
      const { x, y } = casePath.getPointAtLength(
        pathLength * (progressNumber / 100),
      );

      return {
        x: (x / MAP_OVERLAY_VIEW_WIDTH) * clientWidth,
        y: (y / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight,
      };
    },
    [casePath, clientWidth, clientHeight],
  );

  const organPosition = useMemo(() => {
    const position = organ.startPoint === 100 ? 100 : 0;

    return getPosition(position);
  }, [organ, getPosition]);

  const organTopPosition =
    organPosition.y + ORGAN_TOP_OFFSET * organTopOffsetIndex;
  const organZIndex = DEFAULT_Z_INDEX + zIndex;

  let organCurrentColor: string;
  let borderStyle: string | undefined;
  let iconColor: string | undefined;
  if (oscSubStatus === 'Donor OR') {
    organCurrentColor = '#fff';
    iconColor = organColor;
  } else {
    organCurrentColor = showOrganBorder ? '#00E733' : organColor;
    borderStyle = showOrganBorder ? `2px solid ${organColor}` : undefined;
  }

  return (
    <div
      className={styles.caseOrgan}
      style={{
        top: organTopPosition,
        left: organPosition.x,
        zIndex: organZIndex,
      }}
    >
      <CircleIcon
        icon={organ.name}
        color={organCurrentColor}
        size={21}
        borderStyle={borderStyle}
        pinned
        iconColor={iconColor}
      />
    </div>
  );
};
