import { useQuery } from '@tanstack/react-query';

import { REACT_APP_BE_API_STATS_URL } from '../../constants/const';
import { fetchWithMsalHeaders } from '../../helpers/msal';
import { IHistAircraft } from '../../pages/StatsPage/components';

const url = `${REACT_APP_BE_API_STATS_URL}api/stats/aircrafts`;

export const useQueryStatsAircrafts = () =>
  useQuery({
    queryKey: ['StatsAircrafts'],
    queryFn: async () => {
      const [res, msalError] = await fetchWithMsalHeaders(url);

      if (msalError === null) {
        return (await res.json()) as IHistAircraft[];
      }

      console.error('MSAL Error:', msalError);
    },
    select: data => data,
  });
