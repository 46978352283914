import { type EOrganType } from '../types';

export const REACT_APP_BE_API_URL = process.env.REACT_APP_BE_API_URL ?? '';
export const REACT_APP_BE_API_STATS_URL =
  process.env.REACT_APP_BE_API_STATS_URL ?? '';

export const REACT_APP_ENV = process.env.REACT_APP_ENV ?? 'development';
export const REACT_APP_SSO_AUTHORITY =
  process.env.REACT_APP_SSO_AUTHORITY ?? '';
export const REACT_APP_SSO_AUTHORITY_DOMAIN =
  process.env.REACT_APP_SSO_AUTHORITY_DOMAIN ?? '';
export const REACT_APP_SSO_LOG_LEVEL = process.env.REACT_APP_SSO_LOG_LEVEL
  ? +process.env.REACT_APP_SSO_LOG_LEVEL
  : 4;
export const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS ?? '';
export const REACT_APP_SSO_CLIENT_ID =
  process.env.REACT_APP_SSO_CLIENT_ID ?? '';
export const REACT_APP_SSO_REDIRECT_URL =
  process.env.REACT_APP_SSO_REDIRECT_URL ?? '';
export const REACT_APP_SSO_SCOPES = process.env.REACT_APP_SSO_SCOPES ?? '';
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION ?? '';

export const ORGAN_COLORS: Record<EOrganType, string> = {
  Liver: '#AFFB74',
  Lungs: '#87C8FF',
  Heart: '#CC9AFD',
};

export const ORGAN_PROGRESS_COLORS: Record<EOrganType, string> = {
  Liver: '#587E3A',
  Lungs: '#527A9C',
  Heart: '#8263A1',
};

export const ORGAN_SHAPES: Record<EOrganType, string> = {
  Liver: 'rhombus',
  Lungs: 'square',
  Heart: 'circle',
};

export const STATUS_COLORS: Record<string, string> = {
  ok: '',
  warning: '#FF3844',
  accept: '#00E733',
};

export const CURVE_COLORS: Record<EOrganType, string> = {
  Liver: '#9BDD67',
  Lungs: '#6CA5D6',
  Heart: '#A27CC9',
};

export const MAP_OVERLAY_VIEW_WIDTH = 1304;
export const MAP_OVERLAY_VIEW_HEIGHT = 692;
export const PAGE_HEIGHT = 1080;
export const ALERTS_LIST_MAX_HEIGHT = 960;
export const MAP_PAGE_WIDTH = 3840;
export const AIRCRAFTS_MAP_CLUSTER_OFFSET = 60;
export const REACT_APP_API_FETCH_INTERVAL =
  Number(process.env.REACT_APP_API_FETCH_INTERVAL) || 60;
export const REACT_APP_API_FETCH_TRIGGER_SEC =
  Number(process.env.REACT_APP_API_FETCH_TRIGGER_AT) || 5;

export const MAX_CASE_COLUMN_ITEMS = 6;
export const MAX_CASE_COLUMNS = 4;

export const EIGHT_HOURS = 8 * 60;
export const SIX_HOURS = 6 * 60;
export const TWO_HOURS = 2 * 60;

export const AUTO_SCROLL_TIMEOUT = 30 * 1000;
export const AUTO_SCROLL_SPEED = 0.2;
