import classnames from 'classnames';
import { ReactElement } from 'react';

import { ReactComponent as HeartSVG } from '../../../assets/heart.svg';
import { ReactComponent as LiverSVG } from '../../../assets/liver.svg';
import { ReactComponent as LungSVG } from '../../../assets/lung.svg';
import { EOrganType } from '../../../types/new';

import styles from './TableHeader.module.scss';

const organIcon: Record<EOrganType, ReactElement> = {
  Liver: <LiverSVG />,
  Heart: <HeartSVG />,
  Lungs: <LungSVG />,
};
interface TableHeaderOrganGroupProps {
  organ: EOrganType;
}

const TableHeaderOrganGroup = ({ organ }: TableHeaderOrganGroupProps) => (
  <div className={styles['organ-group']}>
    <div className={styles['days-group']}>
      <div
        className={classnames(
          styles['day-group'],
          styles[organ.toLowerCase()],
          styles.today,
        )}
      >
        <div className={styles.title}>
          {organIcon[organ]} {organ}
        </div>
        <div className={styles.subtitle}>Today</div>
        <div className={styles.subheader}>
          <div className={styles.title}>CS</div>
          <div className={styles.title}>Surgeon</div>
          <div className={styles.title}>Device</div>
        </div>
      </div>
      <div
        className={classnames(
          styles['day-group'],
          styles[organ.toLowerCase()],
          styles.tomorrow,
        )}
      >
        <div className={styles.subtitle}>Tomorrow</div>
        <div className={styles.subheader}>
          <div className={styles.title}>CS</div>
          <div className={styles.title}>Surgeon</div>
          <div className={styles.title}>Device</div>
        </div>
      </div>
    </div>
  </div>
);

export const TableHeader = () => (
  <div className={styles.header}>
    <div className={styles.hubs}>
      <div className={styles.primary}>HUB</div>
      <div className={styles.secondary}>Time Zone</div>
    </div>
    <TableHeaderOrganGroup organ={EOrganType.Liver} />
    <TableHeaderOrganGroup organ={EOrganType.Heart} />
    <TableHeaderOrganGroup organ={EOrganType.Lungs} />
  </div>
);
