import { useCallback, useEffect, useRef, useState } from 'react';
import { clearTimeout, setTimeout } from 'worker-timers';

import { AUTO_SCROLL_SPEED, AUTO_SCROLL_TIMEOUT } from '../constants/const';
import {
  requestAnimationWorker,
  cancelAnimationWorker,
} from '../helpers/webworkers/requestAnimationFrameWorker';

interface UseAutoScrollProps {
  element: HTMLDivElement | SVGSVGElement | SVGPathElement | null;
  hasScroll: boolean;
  speed?: number;
  timeout?: number;
}

interface UseAutoScrollReturn {
  start: () => void;
  pause: () => void;
}

export const useAutoScroll = ({
  element,
  hasScroll,
  speed = AUTO_SCROLL_SPEED,
  timeout = AUTO_SCROLL_TIMEOUT,
}: UseAutoScrollProps): UseAutoScrollReturn => {
  const [isAnimationIdle, setAnimationIdle] = useState(true);
  const [isAnimationOnPause, setAnimationOnPause] = useState(false);
  const [currentScrollDirection, setCurrentScrollDirection] = useState<
    'top' | 'bottom'
  >('bottom');
  const animationFrameIdRef = useRef<string | null>(null);
  const timeoutIdRef = useRef<number | null>(null);

  const switchDirection = useCallback(() => {
    setCurrentScrollDirection(prevState => {
      const isBottom = prevState === 'bottom';
      return isBottom ? 'top' : 'bottom';
    });
  }, []);

  const cancelRAF = useCallback(() => {
    if (animationFrameIdRef.current) {
      cancelAnimationWorker(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  }, []);

  const cancelTimeout = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  }, []);

  const handleAnimationRestart = useCallback(() => {
    setAnimationIdle(true);
    cancelRAF();
    cancelTimeout();

    timeoutIdRef.current = setTimeout(() => {
      setAnimationIdle(false);
      cancelTimeout();
    }, timeout);
  }, [timeout, cancelRAF, cancelTimeout]);

  const animateScroll = useCallback(
    (elapsed: number, elementTop: number, scrollHeight: number) => {
      if (element) {
        const isBottomScroll = currentScrollDirection === 'bottom';
        const scrollTop = isBottomScroll
          ? elementTop + Math.min(speed * elapsed, scrollHeight)
          : Math.max(elementTop - speed * elapsed, 0);
        const isScrollEnd = isBottomScroll
          ? scrollTop >= scrollHeight
          : scrollTop <= 0;

        element.scrollTop = scrollTop;

        if (isScrollEnd) {
          handleAnimationRestart();
          switchDirection();
        }
      }
    },
    [
      element,
      currentScrollDirection,
      handleAnimationRestart,
      speed,
      switchDirection,
    ],
  );

  const initScrollAnimation = useCallback(
    (element: HTMLDivElement | SVGSVGElement | SVGPathElement) => {
      const scrollHeight = element.scrollHeight - element.clientHeight;
      const elementTop = element.scrollTop;

      if (scrollHeight > 0) {
        animationFrameIdRef.current = requestAnimationWorker((t: number) =>
          animateScroll(t, elementTop, scrollHeight),
        );
      }
    },
    [animateScroll],
  );

  useEffect(() => {
    setCurrentScrollDirection(prevState => {
      const isTop = prevState === 'top';

      if (!hasScroll) {
        return isTop ? 'bottom' : prevState;
      }

      return prevState;
    });

    handleAnimationRestart();
  }, [handleAnimationRestart, hasScroll]);

  useEffect(() => {
    if (!isAnimationIdle && element) {
      initScrollAnimation(element);
    }
  }, [initScrollAnimation, isAnimationIdle, element]);

  const pauseScrollAnimation = useCallback(() => {
    setAnimationIdle(true);

    if (timeoutIdRef.current) {
      cancelTimeout();
    } else if (animationFrameIdRef.current) {
      cancelRAF();
      setAnimationOnPause(true);
    }
  }, [cancelRAF, cancelTimeout]);

  const startScrollAnimation = useCallback(() => {
    if (isAnimationOnPause) {
      setAnimationIdle(false);
      setAnimationOnPause(false);
    } else {
      handleAnimationRestart();
    }
  }, [isAnimationOnPause, handleAnimationRestart]);

  return {
    start: startScrollAnimation,
    pause: pauseScrollAnimation,
  };
};
