import { useQuery } from '@tanstack/react-query';

import {
  REACT_APP_API_FETCH_INTERVAL,
  REACT_APP_BE_API_URL,
} from '../../constants/const';
import { ISigmet } from '../../types';

import { aviationWeatherFetch } from './aviationWeatherFetch';

const url = `${REACT_APP_BE_API_URL}api/aviation-weather/isigmet`;

export const useQueryISigmets = () =>
  useQuery({
    queryKey: ['iSigmet'],
    queryFn: async (): Promise<ISigmet[]> => await aviationWeatherFetch(url),
    select: data => data,
    refetchInterval: 1000 * REACT_APP_API_FETCH_INTERVAL,
  });
