import { BBox } from '@turf/helpers';

export enum TeamIconType {
  Specialist = 'Specialist',
  Device = 'Device',
  Surgeon = 'Surgeon',
}

export enum TransportIconType {
  groundOnly = 'groundOnly',
  airNonMao = 'airNonMao',
  airMao = 'airMao',
}

export enum ENOPCaseStatus {
  None = 'None',
  Draft = 'Draft',
  TXCenterDraft = 'TXCenterDraft',
  ClinicalScreening = 'ClinicalScreening',
  Declined = 'Declined',
  PendingLogistics = 'PendingLogistics',
  ResourceDeployed = 'ResourceDeployed',
  ResourcesBooked = 'ResourcesBooked',
  AtDonorHospital = 'AtDonorHospital',
  OrganOnOCS = 'OrganOnOCS',
  DeployedToTXCenter = 'DeployedToTXCenter', // In Transit to TX Center
  AtTXCenter = 'AtTXCenter',
  OrganOffOCS = 'OrganOffOCS',
  ServiceCompleted = 'ServiceCompleted',
  ServiceDeclined = 'ServiceDeclined',
  ReturningToBase = 'ReturningToBase',
  Finished = 'Finished',
  Closed = 'Closed',
  Accepted = 'Accepted',
  New = 'New',
  OrTimeConfirmed = 'OrTimeConfirmed',
  QuoteReceived = 'QuoteReceived',
  QuoteSent = 'QuoteSent',
  QuoteRejected = 'QuoteRejected',
  QuoteApproved = 'QuoteApproved',
  Deleted = 'Deleted',
}

export enum ENOPCSShiftType {
  OnCall = 'OnCall',
  PhoneSupport = 'PhoneSupport',
  Vacation = 'Vacation',
  Sick = 'Sick',
  AtHub = 'AtHub',
  Available = 'Available',
  Unavailable = 'Unavailable',
  NoCall = 'NoCall',
  WeekendOff = 'WeekendOff',
  TravelDay = 'TravelDay',
}

export enum ENOPSShiftType {
  OnCall = 'OnCall',
  NoCall = 'NoCall',
  Unavailable = 'Unavailable',
  Vacation = 'Vacation',
  Sick = 'Sick',
}

export type HubLocation = {
  abbreviation: string;
  boundary: string;
  city: string;
  direction: TDirection;
  id: string;
  lonLat: TPoint;
  name: string;
  region: TRegion;
  state: string;
  timeZone: TTimeZone;
};

export type StaffCountsInfo = {
  id: string;
  organType: EOrganType;
  availableCount: number;
  assignedCount: number;
};
export type StaffInfo = {
  id: string;
  teamMemberType: TeamIconType;
  countsInfo: StaffCountsInfo[];
};
export type THubsReportStaffInfo = {
  id: string;
  teamMemberType: TeamIconType;
  details: TStaffDetails[];
};

export enum EOrganType {
  Liver = 'Liver',
  Heart = 'Heart',
  Lungs = 'Lungs',
}
export enum EResourceStatus {
  Available = 'Available',
  Assigned = 'Assigned',
  OnRest = 'OnRest',
}
export type TResourceType = 'Device' | 'Surgeon' | 'Specialist';
export type TScheduleType = 'Office' | 'Blocked' | 'On Call';

export type TTimeZone = 'EDT' | 'CDT' | 'PDT' | 'MST' | 'MDT';
export type TRegion = 'Southeast' | 'Northeast' | 'Midwest' | 'Texas' | 'West';
export type TDirection = 'west' | 'east' | 'north' | 'south';
export type TResourceCommon = {
  name: string;
  status: EResourceStatus;
  unosId?: string;
};

export type TResource = TResourceDevice | TResourceHuman;
export type TResourceDevice = {
  serialNumber: string;
} & TResourceCommon;

export type TResourceHumanSchedule = {
  scheduleType: ENOPCSShiftType | ENOPSShiftType;
  startUTCDateTime: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  status: EResourceStatus;
  endUTCDateTime: string;
  location: string;
  locationID: string;
  region: TRegion;
  timezone: TTimeZone;
};

export type TResourceHuman = {
  today?: TResourceHumanSchedule;
  tomorrow?: TResourceHumanSchedule;
} & TResourceCommon;

export type TStaffDetails = {
  id: string;
  organType: EOrganType;
  resources: TResource[];
};

export type THubsReportStatus = {
  id: string;
  location: HubLocation;
  staffInfo: THubsReportStaffInfo[];
};
export type HubStatusType = {
  id: string;
  location: HubLocation;
  staffInfo: StaffInfo[];
};
export type HubReportStatusType = 'Available' | 'N/A';

export type HubReportType = {
  region: string;
  regionID: string;
  locationID: string;
  location: string;
  todayHeartSpecialistCount: string;
  todayHeartSurgeonCount: string;
  heartDeviceCount: string;
  todayHeartSpecialistReturnTime: string;
  todayHeartSurgeonReturnTime: string;
  tomorrowHeartSpecialistCount: string;
  tomorrowHeartSurgeonCount: string;
  tomorrowHeartSpecialistReturnTime: string;
  tomorrowHeartSurgeonReturnTime: string;
  todayHeartSpecialistCountStatus: HubReportStatusType;
  todayHeartSurgeonCountStatus: HubReportStatusType;
  tomorrowHeartSpecialistCountStatus: HubReportStatusType;
  tomorrowHeartSurgeonCountStatus: HubReportStatusType;
  heartDeviceCountStatus: HubReportStatusType;
  todayLiverSpecialistCount: string;
  todayLiverSurgeonCount: string;
  liverDeviceCount: string;
  todayLiverSpecialistReturnTime: string;
  todayLiverSurgeonReturnTime: string;
  tomorrowLiverSpecialistCount: string;
  tomorrowLiverSurgeonCount: string;
  tomorrowLiverSpecialistReturnTime: string;
  tomorrowLiverSurgeonReturnTime: string;
  todayLiverSpecialistCountStatus: HubReportStatusType;
  tomorrowLiverSpecialistCountStatus: HubReportStatusType;
  tomorrowLiverSurgeonCountStatus: HubReportStatusType;
  todayLiverSurgeonCountStatus: HubReportStatusType;
  liverDeviceCountStatus: HubReportStatusType;
  todayLungSpecialistCount: string;
  todayLungSurgeonCount: string;
  lungDeviceCount: string;
  todayLungSpecialistReturnTime: string;
  todayLungSurgeonReturnTime: string;
  tomorrowLungSpecialistCount: string;
  tomorrowLungSurgeonCount: string;
  tomorrowLungSpecialistReturnTime: string;
  tomorrowLungSurgeonReturnTime: string;
  todayLungSpecialistCountStatus: HubReportStatusType;
  tomorrowLungSpecialistCountStatus: HubReportStatusType;
  tomorrowLungSurgeonCountStatus: HubReportStatusType;
  todayLungSurgeonCountStatus: HubReportStatusType;
  lungDeviceCountStatus: HubReportStatusType;
};

export type Direction = {
  id: string;
  status: string;
  organ: Organ;
  points: (TPoint | null)[];
};

export type CaseTeamMember = {
  id: string;
  name: TeamIconType;
  startPoint: number;
};

export type CheckPoint = {
  id: string;
  point: number;
  type?: 'organ' | 'groundOnly' | 'airNonMao' | 'airMao' | 'donorOR';
  hidden: boolean;
  subId?: string;
};

export type NewCaseType = {
  id: string;
  unosDonorId: string;
  label: string;
  donorType: DonorType;

  oscStatus: string;
  oscSubStatus: string;

  donorORTimeString: string;
  caseTimerEndString: string | null;
  organ: Organ;
  donorHospital: Hospital;
  transplantCenterCode: string;
  showOnMap: boolean;

  sameUnosNewCases: NewCaseType[];
};

export type Case = {
  id: string;
  unosDonorId: string;
  flightIds: string[];

  label: string;
  donorType: DonorType;
  transplantCenterCode: string;

  etaString: string;
  donorORTimeString: string;
  caseTimerEndString: string | null;
  showDonorOr: boolean;
  showOrgan: boolean;
  showTimer: boolean;
  showDoctorOperation: boolean;
  showReturnToBaseState: boolean;
  showOnMap: boolean;
  showOrganBorder: boolean;
  showTransportsOnMap: boolean;
  showTeamOnMap: boolean;

  showOrganOnCard: boolean;
  showOrganColor: boolean;
  showTransportOnCard: boolean;

  hasErrors: boolean;

  status: ENOPCaseStatus;
  oscSubStatus: string;
  cardStatus: string;

  // progress bar items
  organ: Organ;
  pathLabels: PathLabel[];
  transport?: TransportName;
  points: CheckPoint[];
  team: CaseTeamMember[];
  progress: number;
  mapProgress: number;

  // not sure if needed directly
  donorHospital: Hospital;
  recipientHospital: Hospital;

  viewData?: {
    pointA: number[] | null;
    pointB: number[] | null;
    curve: string | null;
    organTopOffset: number;
  };
};

export type DonorType = 'DCD' | 'DBD';

export type TransportName = 'groundOnly' | 'airNonMao' | 'airMao';

export type Transport = {
  id: string;
  name: TransportName;
  startPoint: number;
};

export type Hospital = {
  location: string; // TODO: check if needed
  address: string; // TODO: check if needed
  state: string; // TODO: check if needed
  lonLat: TPoint | null;
  timezone: string;
  boundary: string | null;
};

export type PathLabel = {
  id: string;
  name: string;
  startPoint: number;
  isActive: boolean;
};

export type Organ = {
  id: string;
  name: EOrganType;
  startPoint: number;
};

export type TPoint = [number, number];
// errors of whole app
export type AppDataErrors = Record<string, DataErrors>;
// errors of single type, like Hub or Case, etc
export type DataErrors = Record<
  string,
  { label: string; errorMessages: string[] }
>;

export enum EAircraftEventType {
  OnDuty = 'OnDuty',
  OffDuty = 'OffDuty',
  NotAvailable = 'NotAvailable',
  OnTrip = 'OnTrip',
  OnMaintenance = 'OnMaintenance',
}

export type AircraftEvent = {
  Id: string;
  Type: EAircraftEventType;
  EventTimeMs: number;
  EventTimeString: string;
  StartTime: string;
  EndTime: string;
  Label?: string;
  Organ?: Organ;
  IsLate?: boolean;
  MaintenanceType?: string;
  MaintenanceName?: string;
};

export type AirportType = {
  Id: number;
  IATA: string;
  Latitude: number;
  Longitude: number;
  ICAO: string;
  ServedCity: string;
  ServedCityArea: {
    Code: string;
  } | null;
  Country: {
    Name: string;
  };
  Boundary?: string | null;
  IsNextArrival?: boolean;
};

export type AircraftOnAirportType = {
  Id: string;
  Type: TAircraftOnMapType;
  Location?: AircraftLocationType;
  Registration: string;
  TimeOnDuty: number;
  Airport: AirportType | null;
  MaintenanceType?: string;
  MaintenanceName?: string;
};

export type AircraftOnFlyType = {
  Id: string;
  Registration: string;
  IsOnFly: boolean;
  Direction: string | null;
  Airports: AirportType[];
  TripLegStart: string | null;
  TripLegEnd: string | null;
  Organ?: EOrganType;
  Label?: string;
  IsLate?: boolean;
  IsOnCase?: boolean;
  IsReturnToBase?: boolean;
  Location: AircraftLocationType;
};
export type TAircraftOnMapType = 'OnGround' | 'OnMaintenance' | 'OnFly';
export type TAircraftOnMap =
  | ({
      Type: 'OnGround';
    } & AircraftOnAirportType)
  | ({
      Type: 'OnMaintenance';
    } & AircraftOnAirportType & {
        MaintenanceType?: string;
        MaintenanceName?: string;
      })
  | ({
      Type: 'OnFly';
    } & AircraftOnFlyType);

export type TAircraftList = TAircraftOnMap[];

export type AircraftLocationType = {
  AircraftId: number;
  Latitude: number | null;
  Longitude: number | null;
  Boundary: string | null;
};

type BaseAircraftInfo = {
  Id: number;
  Registration: string;
  Status: string;
  Events: AircraftEvent[];
};

export type AircraftStatusType =
  | {
      Type: 'OnGround';
      Location: AircraftLocationType | null;
      Airport: AirportType | null;
    }
  | {
      Type: 'OnFly';
      Location: AircraftLocationType | null;
      IsOnCase: boolean;
      IsOnFly: boolean;
      IsReturnToBase: boolean;
      TripLegStart: string | null;
      TripLegEnd: string | null;
      Direction: string;
      Airports: AirportType[];
    }
  | {
      Type: 'OnMaintenance';
      Airport: AirportType | null;
      MaintenanceType: string;
      MaintenanceName: string;
    };

type AircraftCardItemType =
  | {
      Type: 'OnGround';
      Location: AircraftLocationType | null;
      Airport: AirportType | null;
    }
  | {
      Type: 'OnFly';
      Location: AircraftLocationType | null;
      IsOnCase: boolean;
      IsOnFly: boolean;
      TripLegStart: string | null;
      TripLegEnd: string | null;
      IsReturnToBase: boolean;
      Direction: string;
      Airports: AirportType[];
    }
  | {
      Type: 'OnMaintenance';
      Airport: AirportType | null;
    };

export type AircraftCardItem = BaseAircraftInfo & AircraftCardItemType;

export type AircraftStatus = BaseAircraftInfo & AircraftStatusType;

export type AircraftStatusesListResponse = {
  list: AircraftStatus[];
  statuses: Record<string, number>;
};

export type AircraftPositionLeg = {
  start: AirportType;
  end: AirportType;
  startTime: string;
  endTime: string;
};

export type AllData = {
  activeCases: Case[];
  newCases: NewCaseType[];
  hubs: HubStatusType[];
  hubsReport: THubsReportStatus[];
  aircrafts: AircraftStatusesListResponse;
  statistics: StatisticsType;
  dataErrors: AppDataErrors;
  networkErrors: AppDataErrors;
};

export type BboxArrayItem = {
  key: string;
  bBox: BBox;
};

export type CaseBboxArrayItem = {
  key: string;
  bBoxArray: BBox[];
  items: string[];
};

export enum ClusterDataEnum {
  Case = 'case',
  OnGround = 'onGround',
  OnFly = 'onFly',
}

export type ClusterData<T> = {
  location: number[];
  size: number[];
} & T;

export type AircraftClusterTypes =
  | {
      type: ClusterDataEnum.OnGround;
      data: AircraftOnAirportType;
    }
  | {
      type: ClusterDataEnum.OnFly;
      data: AircraftOnFlyType;
    };

export type CaseClusterType = {
  type: ClusterDataEnum.Case;
  data: Case;
};

type ClusterBboxData = {
  key: string;
  clusterLocation: number[];
  bBox: BBox;
};

export type ClusterBboxItem<T> = ClusterBboxData & {
  items: ClusterData<T>[];
};

export type ClusterItem<T> = {
  key: string;
  isCluster: boolean;
  items: ClusterData<T>[];
};

export type ExcludedCasesType = {
  cases: Case[];
  excluded: Case[];
};

export type CasesShareStatisticsType = {
  ground: number;
  airGround: number;
  tmdxShare: number;
};
export type AviationStatisticsType = {
  crewTotalHours: number;
  crewOnCaseHours: number;
  crewOnNonCaseHours: number;
  crewWaitingForCaseHours: number;
  maintenancesHours: number;
  noCrewHours: number;
  days: number;
};

export type CasesStatisticsType = {
  allCases: number;
  startDate: string;
} & CasesShareStatisticsType;

export type StatisticsType = {
  casesStatistics: CasesStatisticsType;
  aviationStatistics: AviationStatisticsType;
  fleetAvailability: number;
  lastUpdated: string;
};
