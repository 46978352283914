import classnames from 'classnames';
import { Fragment, useMemo } from 'react';

import { ReactComponent as PlaneSVG } from '../../../assets/plane.svg';
import { ReactComponent as PlaneOnFlySVG } from '../../../assets/planeOnFly.svg';
import { useElementRef } from '../../../hooks/useElementRef';
import { AircraftOnFlyType, type EOrganType } from '../../../types/new';
import styles from '../overlays.module.scss';

interface IconsProps
  extends Pick<
    AircraftOnFlyType,
    | 'Direction'
    | 'Registration'
    | 'Id'
    | 'Label'
    | 'Organ'
    | 'IsLate'
    | 'IsOnFly'
    | 'IsOnCase'
    | 'IsReturnToBase'
    | 'Airports'
  > {
  isSetElementRef?: boolean;
}

export const AircraftOnFlyIcons = ({
  Id,
  Direction,
  Registration,
  Organ,
  Label,
  Airports,
  IsOnFly,
  IsLate,
  IsOnCase,
  IsReturnToBase,
  isSetElementRef = false,
}: IconsProps) => {
  const { ref } = useElementRef<HTMLDivElement>(`${Id}-aircraft-icons`);

  const aircraftsMapStyles = useMemo(
    () => ({
      [styles[`aircraftMap${Organ as EOrganType}`]]:
        !!Organ && !IsLate && !IsReturnToBase,
      [styles.aircraftMapLate]: IsLate && !IsReturnToBase,
      [styles.aircraftMapReturnToBase]: IsReturnToBase,
    }),
    [Organ, IsLate, IsReturnToBase],
  );

  return (
    <div
      ref={isSetElementRef ? ref : null}
      className={styles.aircraftOnMap}
      style={{
        alignItems: 'center',
      }}
    >
      <div className={classnames(styles.aircraftInfoWithLabel)}>
        {!!Label && (
          <div
            className={classnames(styles.aircraftUnosLabel, {
              ...aircraftsMapStyles,
            })}
          >
            {Label}
          </div>
        )}
        <div className={classnames(styles.aircraftInfo)}>
          <span
            className={classnames(styles.registration, {
              ...aircraftsMapStyles,
            })}
          >
            {Registration}
          </span>
          <div className={styles.aircraftAirportInfo}>
            {Airports.map((item, index) => {
              const airportName = item?.IATA || item?.ICAO;
              if (item?.IsNextArrival) {
                return (
                  <Fragment key={`${item.Id}-${Label}`}>
                    <div
                      className={classnames(styles.aircraftMapIcon, {
                        [styles.planeWestDirection]: Direction === 'west',
                        ...aircraftsMapStyles,
                      })}
                    >
                      {IsOnFly ? <PlaneOnFlySVG /> : <PlaneSVG />}
                    </div>
                    <span
                      className={classnames(styles.registration, {
                        ...aircraftsMapStyles,
                      })}
                      style={{ opacity: 0.7 }}
                    >
                      {airportName}
                    </span>
                  </Fragment>
                );
              }
              return (
                <Fragment key={`${item.Id}-${Label}`}>
                  <span
                    className={classnames(styles.registration, {
                      ...aircraftsMapStyles,
                    })}
                    style={{ opacity: 0.7 }}
                  >{`${index ? '•' : ''}${airportName}`}</span>
                </Fragment>
              );
            })}
            {!IsOnCase && (
              <div
                className={styles.aircraftTime}
                style={{ marginLeft: '4px' }}
              >
                X
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
