import {
  ClientAuthError,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  type Configuration,
  type EventMessage,
  BrowserAuthErrorCodes,
  type BrowserAuthError,
} from '@azure/msal-browser';

import {
  REACT_APP_SSO_AUTHORITY,
  REACT_APP_SSO_AUTHORITY_DOMAIN,
  REACT_APP_SSO_CLIENT_ID,
  REACT_APP_SSO_LOG_LEVEL,
  REACT_APP_SSO_REDIRECT_URL,
  REACT_APP_SSO_SCOPES,
} from '../constants/const';

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_SSO_CLIENT_ID,
    authority: REACT_APP_SSO_AUTHORITY,
    knownAuthorities: [REACT_APP_SSO_AUTHORITY_DOMAIN],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii || level > REACT_APP_SSO_LOG_LEVEL) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: REACT_APP_SSO_SCOPES.split(','),
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

// msalInstance.getTokenCache().storage.getCachedRequest()

msalInstance.addEventCallback((event: EventMessage) => {
  const eventsArr: EventType[] = [
    EventType.ACCOUNT_ADDED,
    EventType.ACCOUNT_REMOVED,
    EventType.LOGIN_SUCCESS,
    EventType.SSO_SILENT_SUCCESS,
    EventType.HANDLE_REDIRECT_END,
    EventType.LOGIN_FAILURE,
    EventType.SSO_SILENT_FAILURE,
    EventType.LOGOUT_END,
    EventType.ACQUIRE_TOKEN_SUCCESS,
    EventType.ACQUIRE_TOKEN_FAILURE,
  ];
  const isNeededEvent = eventsArr.includes(event.eventType);

  if (isNeededEvent) {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      const redirectPath = (event.payload as { state?: string })?.state;
      if (redirectPath) {
        localStorage.setItem('TSMED_SSO_STATE_PATH', redirectPath);
      }
      // if (redirectPath) {
      //   setTimeout(() => {
      //     location.pathname = redirectPath;
      //   }, 100);
      // }
    }
  }
});

const handleMsalError = (e: unknown) => {
  const errorString = (e as Error).toString();
  const isSsoError =
    errorString.includes('Correlation ID') ||
    (errorString.includes('BrowserAuthError') &&
      (e as BrowserAuthError).errorCode !==
        BrowserAuthErrorCodes.noNetworkConnectivity);
  if (isSsoError) {
    return 'CCS_MSAL. Error.';
  }
  throw e;
};

const getActiveMsalAccount = async (td?: NodeJS.Timeout) => {
  const account = msalInstance.getActiveAccount();

  if (account) {
    clearTimeout(td);
    return account;
  }

  const timeoutDescriptor = setTimeout(() => {
    getActiveMsalAccount(timeoutDescriptor);
    msalInstance.loginRedirect({
      ...loginRequest,
      state: location.pathname,
    });
  }, 500);

  // throw Error(
  //   'CCS_MSAL. No active account! Verify a user has been signed in and setActiveAccount has been called.',
  // );
};

const acquireMsalToken = async ({ forceRefresh }: { forceRefresh: boolean }) =>
  msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: await getActiveMsalAccount(),
      forceRefresh,
      redirectUri: REACT_APP_SSO_REDIRECT_URL,
      // refreshTokenExpirationOffsetSeconds: 30, // 5 minutes
    })
    .catch(async error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenSilent(loginRequest);
      } else if (
        error instanceof ClientAuthError &&
        error.errorMessage.includes('interaction_required')
      ) {
        console.error(error.errorMessage);
        msalInstance.acquireTokenRedirect(loginRequest);
      } else if (
        error instanceof ClientAuthError &&
        error.errorCode === 'block_token_requests'
      ) {
        console.error(error.errorMessage);
      } else if (
        error instanceof ClientAuthError &&
        error.errorCode === 'user_cancelled'
      ) {
        console.error(error.errorMessage);
      } else if (
        error instanceof ClientAuthError &&
        error.errorCode === 'login_progress_error'
      ) {
        console.error(error.errorMessage);
      } else {
        throw error;
      }
    });
const tryFetchWithMsalHeaders = async ({
  url,
  forceRefresh,
}: {
  url: string;
  forceRefresh: boolean;
}) => {
  const msalTokenResponse = await acquireMsalToken({
    forceRefresh,
  });
  if (!msalTokenResponse) {
    throw Error('CCS_MSAL. Failed to acquire token.');
  }
  const msalHeaders = {
    Authorization: `Bearer ${msalTokenResponse.accessToken}`,
  };
  return fetch(url, { headers: msalHeaders });
};

export const fetchWithMsalHeaders = async (
  url: string,
): Promise<[Response, null] | [null, string]> => {
  let msalError;

  try {
    return [await tryFetchWithMsalHeaders({ url, forceRefresh: false }), null];
  } catch (e) {
    console.info(
      'CCS_MSAL. Check Auth token without refresh:',
      (e as Error).toString(),
    );
  }

  try {
    return [await tryFetchWithMsalHeaders({ url, forceRefresh: true }), null];
  } catch (e) {
    console.info(
      'CCS_MSAL. Check Auth token with refresh:',
      (e as Error).toString(),
    );
    // if error comes from SSO, set it. If not - Throw new Error
    msalError = handleMsalError(e);
  }

  return [null, msalError];
};
