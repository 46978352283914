import { useMemo } from 'react';

import {
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
  ORGAN_COLORS,
} from '../../../constants/const';
import { getPointPixelsCoordinates } from '../../../helpers/pathGeneration';
import { NewCaseType } from '../../../types/new';
import { CircleIcon } from '../../CircleIcon';
import styles from '../overlays.module.scss';

interface Props {
  newCase: NewCaseType;
  clientWidth: number;
  clientHeight: number;
  zIndex: number;
  organIndex?: number;
  isSameUnos?: boolean;
}

export const NewCaseOrgan = ({
  newCase,
  clientWidth,
  clientHeight,
  zIndex,
  organIndex,
  isSameUnos = false,
}: Props) => {
  const xMargin = isSameUnos && organIndex ? 25 * organIndex : 0;

  const iconPosition = useMemo<{ x: number; y: number } | null>(() => {
    if (!newCase.donorHospital.lonLat || !newCase.donorHospital.boundary) {
      return null;
    }

    const [x, y] = getPointPixelsCoordinates(
      newCase.donorHospital.lonLat,
      newCase.donorHospital.boundary,
    );

    return {
      x: (x / MAP_OVERLAY_VIEW_WIDTH) * clientWidth,
      y: (y / MAP_OVERLAY_VIEW_HEIGHT) * clientHeight,
    };
  }, [
    newCase.donorHospital.lonLat,
    newCase.donorHospital.boundary,
    clientWidth,
    clientHeight,
  ]);

  return iconPosition ? (
    <div
      key={newCase.organ.id}
      className={styles.newCaseOrgan}
      style={{
        top: iconPosition.y,
        left: iconPosition.x,
        zIndex,
        marginLeft: xMargin,
      }}
    >
      <CircleIcon
        icon={newCase.organ.name}
        color="#FF975D"
        iconColor="#000000"
        pinned={!isSameUnos}
        size={21}
        borderStyle={`2px solid ${ORGAN_COLORS[newCase.organ.name]}`}
      />
    </div>
  ) : null;
};
