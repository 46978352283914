import { useQuery } from '@tanstack/react-query';

import { REACT_APP_BE_API_STATS_URL } from '../../constants/const';
import { fetchWithMsalHeaders } from '../../helpers/msal';
import type { StatsDowntimeAircraft, StatsDowntimeSummary } from '../../types';

import { StatsQueryParams } from './types';

const url = `${REACT_APP_BE_API_STATS_URL}api/stats/downtime`;
export const useQueryStatsDowntime = ({
  dateTo,
  fromUnitType,
  fromValue,
  aircrafts = [],
}: StatsQueryParams) => {
  const enabled = ![fromUnitType, fromValue, dateTo].some(v => v === undefined);
  const dateToString = dateTo.toISOString();
  return useQuery({
    queryKey: [
      'StatsDowntime',
      fromValue,
      fromUnitType,
      dateToString,
      aircrafts,
    ],
    queryFn: async () => {
      const [res, msalError] = await fetchWithMsalHeaders(
        `${url}?${new URLSearchParams({
          dateTo: dateToString,
          aircrafts: aircrafts.toString(),
          fromValue: `${fromValue}`,
          fromUnitType,
        })}`,
      );

      if (msalError === null) {
        return (await res.json()) as {
          perDate: Record<string, StatsDowntimeSummary>;
          summary: StatsDowntimeSummary;
          perAircraft: Record<string, StatsDowntimeAircraft>;
        };
      }
      console.error('MSAL Error:', msalError);
    },
    select: data => data,
    enabled,
  });
};
