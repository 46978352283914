import {
  styled,
  tableClasses,
  tableBodyClasses,
  tableCellClasses,
  tableRowClasses,
  tableHeadClasses,
  Drawer,
  drawerClasses,
} from '@mui/material';

import { ORGAN_COLORS } from '../../../constants/const';

const PREFIX = 'TSMED-hubsReport-CellDrawer';

export const cellDrawerClasses = {
  header: `${PREFIX}-header`,
  headerPrimary: `${PREFIX}-headerPrimary`,
  headerPrimaryIcon: `${PREFIX}-headerPrimaryIcon`,
  headerPrimaryTitle: `${PREFIX}-headerPrimaryTitle`,
  headerPrimaryClose: `${PREFIX}-headerPrimaryClose`,
  headerSecondary: `${PREFIX}-headerSecondary`,
  headerSecondaryIcon: `${PREFIX}-headerSecondaryIcon`,
  headerSecondaryLocation: `${PREFIX}-headerSecondaryLocation`,
  headerSecondaryText: `${PREFIX}-headerSecondaryText`,
  headerSecondaryDay: `${PREFIX}-headerSecondaryDay`,
  resourceTableContainer: `${PREFIX}-resourceTableContainer`,
  counts: `${PREFIX}-counts`,
  countBlock: `${PREFIX}-countBlock`,
  countsIcon: `${PREFIX}-countsIcon`,
  countsNumber: `${PREFIX}-countsNumber`,
  countsText: `${PREFIX}-countsText`,
  humanResources: `${PREFIX}-humanResources`,
  deviceResources: `${PREFIX}-deviceResources`,
  resourceName: `${PREFIX}-resourcesName`,
  resourceOnRest: `${PREFIX}-resourceOnRest`,
  resourceStatus: `${PREFIX}-resourcesStatus`,
  resourceScheduleSubType: `${PREFIX}-resourceScheduleSubType`,
  resourceUnosId: `${PREFIX}-resourceTableUnosId`,
  resourceSerialNumber: `${PREFIX}-resourceTableSerialNumber`,
  resourceDateTime: `${PREFIX}-resourceDateTime`,
  resourceDate: `${PREFIX}-resourceDate`,
  resourceTime: `${PREFIX}-resourceTime`,
  resourceReturnTime: `${PREFIX}-resourceReturnTime`,
  resourceReturningTable: `${PREFIX}-resourceReturningTable`,
  available: `${PREFIX}-available`,
  unavailable: `${PREFIX}-unavailable`,
  assigned: `${PREFIX}-assigned`,
};

export const CellDrawerRoot = styled(Drawer, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { palette, spacing } }) => ({
  [`.${drawerClasses.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    gap: 32,
    background: '#1F2B37',
    width: 650,
    boxSizing: 'border-box',
  },
  [`.${cellDrawerClasses.header}`]: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
  },

  [`.${cellDrawerClasses.headerPrimary}`]: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  [`.${cellDrawerClasses.headerPrimaryIcon}`]: {
    width: 32,
    height: 32,
    // background: '#AFFB74',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    [`&.Liver`]: {
      background: ORGAN_COLORS.Liver,
    },
    [`&.Lungs`]: {
      background: ORGAN_COLORS.Lungs,
    },
    [`&.Heart`]: {
      background: ORGAN_COLORS.Heart,
    },
    svg: {
      width: 18,
      height: 18,
      color: 'black',
    },
  },
  [`.${cellDrawerClasses.headerPrimaryTitle}`]: {
    fontSize: '26px',
    color: '#FFFFFF',
    fontWeight: 600,
    flexGrow: 1,
  },
  [`.${cellDrawerClasses.headerPrimaryClose}`]: {
    display: 'flex',
    cursor: 'pointer',
    svg: {
      width: 14,
      height: 14,
      fill: palette.grey[200],
    },
  },
  [`.${cellDrawerClasses.headerSecondary}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  [`.${cellDrawerClasses.headerSecondaryLocation}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontSize: 16,
    color: palette.grey[200],
  },
  [`.${cellDrawerClasses.headerSecondaryIcon}`]: {
    display: 'flex',
    svg: {
      height: 16,
      width: 16,
      fill: palette.grey[200],
    },
  },
  [`.${cellDrawerClasses.headerSecondaryText}`]: {},
  [`.${cellDrawerClasses.headerSecondaryDay}`]: {
    background: '#364B5F',
    lineHeight: '24px',
    display: 'block',
    fontSize: '12px',
    borderRadius: 24,
    padding: '0 12px',
    textTransform: 'capitalize',
    fontWeight: 600,
    color: palette.grey[200],
  },
  [`.${cellDrawerClasses.resourceTableContainer}`]: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
    paddingBottom: 48,
  },
  [`.${cellDrawerClasses.counts}`]: {
    display: 'flex',
    gap: 16,
  },
  [`.${cellDrawerClasses.countBlock}`]: {
    flexGrow: 1,
    border: '1px solid',
    display: 'flex',
    padding: '12px 16px',
    gap: 8,
    fontSize: '20px',
    alignItems: 'center',
    borderRadius: 4,
    [`&.${cellDrawerClasses.available}`]: {
      background: 'rgba(131, 188, 98, 0.1)',
    },
    [`&.${cellDrawerClasses.unavailable}`]: {
      background: 'rgba(182, 182, 182, 0.1)',
    },
    [`&.${cellDrawerClasses.assigned}`]: {
      background: 'rgba(187, 52, 64, 0.1)',
    },
  },
  [`.${cellDrawerClasses.countsIcon}`]: {
    svg: {
      display: 'block',
      height: 18,
      width: 18,
    },
  },
  [`.${cellDrawerClasses.countsNumber}`]: {},
  [`.${cellDrawerClasses.countsText}`]: {},
  [`.${tableClasses.root}`]: {
    background: '#141F29',
    borderRadius: 4,
    border: '1px solid #364B5F',
    display: 'block',
    [`.${tableCellClasses.root}`]: {
      boxSizing: 'border-box',
    },
    [`.${tableHeadClasses.root}`]: {
      [`.${tableRowClasses.root}`]: {
        [`.${tableCellClasses.root}`]: {
          fontSize: '12px',
          fontWeight: 500,
          color: '#7591AD',
          borderBottom: '1px solid #364B5F',
          padding: '4px 8px',
          lineHeight: '16px',
        },
      },
    },
    [`.${tableBodyClasses.root}`]: {},
    [`.${tableRowClasses.root}`]: {
      ['&.odd']: {
        background: '#1C2733',
      },
    },
  },
  [`.${tableBodyClasses.root}`]: {
    [`.${tableCellClasses.root}`]: {
      borderBottom: 'none',
      color: palette.grey[200],
      padding: '16px 8px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  [`.${cellDrawerClasses.humanResources}`]: {
    [`.${cellDrawerClasses.resourceOnRest}`]: {
      [`.${cellDrawerClasses.resourceName}`]: {
        [`.inner`]: {
          background: palette.mapBlue[500],
          padding: spacing(0.5),
          borderRadius: spacing(1),
        },
      },
    },
    [`.${tableCellClasses.root}`]: {
      [`&.${cellDrawerClasses.resourceName}`]: {
        width: 150,
      },
      [`&.${cellDrawerClasses.resourceScheduleSubType}`]: {
        width: 96.5,
        maxWidth: 96.5,
      },
      [`&.${cellDrawerClasses.resourceDateTime}`]: {
        textAlign: 'right',
        width: 84,
        [`.${cellDrawerClasses.resourceDate}`]: {
          fontSize: '14px',
        },
        [`.${cellDrawerClasses.resourceTime}`]: {
          fontSize: '12px',
          color: '#7F8083',
          fontWeight: 400,
        },
      },
      [`&.${cellDrawerClasses.resourceStatus}`]: {
        width: 92,
        span: {
          display: 'block',
          color: '#ffffff',
          padding: '4px 12px',
          borderRadius: 4,
          fontSize: '12px',
        },
      },
      [`&.${cellDrawerClasses.resourceUnosId}`]: {
        width: 93,
        textAlign: 'right',
      },
      [`&.${cellDrawerClasses.resourceReturnTime}`]: {
        width: 80,
        textAlign: 'right',
      },
    },
  },
  [`.${cellDrawerClasses.deviceResources}`]: {
    [`.${tableCellClasses.root}`]: {
      [`&.${cellDrawerClasses.resourceName}`]: {
        width: 285,
      },
      [`&.${cellDrawerClasses.resourceScheduleSubType}`]: {
        width: 96.5,
        maxWidth: 96.5,
      },
      [`&.${cellDrawerClasses.resourceStatus}`]: {
        width: 92,
        span: {
          display: 'block',
          color: '#ffffff',
          padding: '4px 12px',
          borderRadius: 4,
          fontSize: '12px',
          fontWeight: 500,
        },
      },
      [`&.${cellDrawerClasses.resourceSerialNumber}`]: {
        width: 130,
        textAlign: 'right',
      },
      [`&.${cellDrawerClasses.resourceUnosId}`]: {
        width: 93,
        textAlign: 'right',
      },
    },
  },
  [`.${cellDrawerClasses.resourceReturningTable}`]: {
    gap: 16,
    display: 'flex',
    flexDirection: 'column',
    [`.${cellDrawerClasses.headerPrimaryTitle}`]: {
      fontSize: '24px',
      fontWeight: 500,
    },
    [`.${tableCellClasses.root}`]: {
      [`&.${cellDrawerClasses.resourceName}`]: {
        width: 230,
      },
      [`&.${cellDrawerClasses.resourceScheduleSubType}`]: {
        width: 184.5,
        maxWidth: 'none',
      },
      [`&.${cellDrawerClasses.resourceStatus}`]: {
        width: 85,
        span: {
          background: '#364B5F',
        },
      },
      [`&.${cellDrawerClasses.resourceReturnTime}`]: {
        width: 99,
        textAlign: 'right',
        fontSize: '14px',
        fontWeight: 400,
      },
    },
  },
  [`.${cellDrawerClasses.available}`]: {
    color: '#83BC62',
    background: '#83BC62',
  },
  [`.${cellDrawerClasses.unavailable}`]: {
    color: '#7F8083',
    background: '#7F8083',
  },
  [`.${cellDrawerClasses.assigned}`]: {
    color: '#FF4848',
    background: '#FF4848',
  },
}));
