import classnames from 'classnames';
import { useContext, useState } from 'react';
import { isSafari } from 'react-device-detect';

import { ReactComponent as HubSVG } from '../../assets/hub.svg';
import { ReactComponent as MonitorMenuSVG } from '../../assets/monitorMenuIcon.svg';
import { ReactComponent as PlaneMenuSVG } from '../../assets/planeMenuIcon.svg';
import { ReactComponent as PlannerMenuSVG } from '../../assets/plannerMenuIcon.svg';
import { ReactComponent as WeatherSVG } from '../../assets/weather.svg';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import {
  PageDropdown,
  type PageDropdownProps,
} from '../../components/PageDropdown';
import { allRoutes as routes } from '../../constants/routes';
import { MapContext } from '../../contexts/MapContext';
import { AircraftCardsPageContent } from '../AircraftCardsPage/AircraftCardsPage';
import { AircraftsMapPageContent } from '../AircraftsMapPage/AircraftsMapPage';
import { ErrorsPageContent } from '../ErrorsPage/ErrorsPage';
import { HubsReportPageContent } from '../HubsReportPage';
import { MainMapPageContent } from '../MainMapPage/MainMapPage';
import { StatisticsPageContent } from '../StatisticsPage';
import { WeatherPageContent } from '../WeatherPage';

import styles from './ComboPage.module.scss';

const dropdownItems: PageDropdownProps['items'] = [
  {
    id: routes.AIRCRAFTS_MAP,
    icon: <PlaneMenuSVG />,
    title: 'Air Fleet Tracker',
    type: 'route',
  },
  {
    id: routes.AIRCRAFTS_CARDS,
    icon: <PlannerMenuSVG />,
    title: 'Air Fleet Planner',
    type: 'route',
  },
  {
    id: routes.ERRORS,
    icon: <MonitorMenuSVG />,
    title: 'System Monitor',
    type: 'route',
  },
  {
    id: routes.STATISTICS,
    icon: <MonitorMenuSVG />,
    title: 'Statistics',
    type: 'route',
  },
  {
    id: routes.HUBS_REPORT,
    icon: <HubSVG />,
    title: 'Hubs Report',
    type: 'route',
  },
  {
    id: routes.WEATHER,
    icon: <WeatherSVG />,
    title: 'Aviation Weather',
    type: 'route',
  },
];

export const ComboPage = () => {
  const [leftWindow, setLeftWindow] = useState<string>(routes.AIRCRAFTS_MAP);
  const [rightWindow, setRightWindow] = useState<string>(
    routes.AIRCRAFTS_CARDS,
  );

  const { allData, backendNetworkError, lastUpdateTime, appDataErrors } =
    useContext(MapContext);

  const onLeftWindowChange = (value: string) => {
    if (value === rightWindow) {
      setRightWindow(leftWindow);
    }

    setLeftWindow(value);
  };

  const onRightWindowChange = (value: string) => {
    if (value === leftWindow) {
      setLeftWindow(rightWindow);
    }

    setRightWindow(value);
  };

  return (
    (allData && lastUpdateTime && (
      <Layout>
        <div className={styles.layout}>
          <div
            className={classnames({
              [styles.page]: !isSafari,
              [styles.pageSafari]: isSafari,
              [styles.pageSafariTop]: isSafari,
            })}
          >
            <MainMapPageContent
              cases={allData.activeCases}
              newCases={allData.newCases}
              hubs={allData.hubs}
              appDataErrors={appDataErrors}
              lastUpdateTime={lastUpdateTime}
            />
          </div>
          <div className={styles.bottom}>
            <div className={styles.window}>
              <PageDropdown
                id="leftWindow"
                items={dropdownItems}
                value={leftWindow}
                onChange={onLeftWindowChange}
              />
              <div
                className={classnames({
                  [styles.page]: !isSafari,
                  [styles.pageSafari]: isSafari,
                })}
              >
                {leftWindow === routes.AIRCRAFTS_CARDS && (
                  <AircraftCardsPageContent
                    aircraftsList={allData.aircrafts.list}
                    activeCases={allData.activeCases}
                    backendNetworkError={backendNetworkError}
                  />
                )}
                {leftWindow === routes.AIRCRAFTS_MAP && (
                  <AircraftsMapPageContent />
                )}
                {leftWindow === routes.ERRORS && (
                  <ErrorsPageContent
                    cases={allData.activeCases}
                    newCases={allData.newCases}
                    dataErrors={allData.dataErrors}
                    networkErrors={allData.networkErrors}
                    lastUpdateTime={lastUpdateTime}
                  />
                )}
                {leftWindow === routes.STATISTICS && <StatisticsPageContent />}
                {leftWindow === routes.HUBS_REPORT && <HubsReportPageContent />}
                {leftWindow === routes.WEATHER && <WeatherPageContent />}
              </div>
            </div>
            <div className={styles.window}>
              <PageDropdown
                id="rightWindow"
                items={dropdownItems}
                value={rightWindow}
                onChange={onRightWindowChange}
              />
              <div
                className={classnames({
                  [styles.page]: !isSafari,
                  [styles.pageSafari]: isSafari,
                })}
              >
                {rightWindow === routes.AIRCRAFTS_CARDS && (
                  <AircraftCardsPageContent
                    aircraftsList={allData.aircrafts.list}
                    activeCases={allData.activeCases}
                    backendNetworkError={backendNetworkError}
                  />
                )}
                {rightWindow === routes.AIRCRAFTS_MAP && (
                  <AircraftsMapPageContent />
                )}
                {rightWindow === routes.ERRORS && (
                  <ErrorsPageContent
                    cases={allData.activeCases}
                    newCases={allData.newCases}
                    dataErrors={allData.dataErrors}
                    networkErrors={allData.networkErrors}
                    lastUpdateTime={lastUpdateTime}
                  />
                )}
                {rightWindow === routes.STATISTICS && <StatisticsPageContent />}
                {rightWindow === routes.HUBS_REPORT && (
                  <HubsReportPageContent />
                )}
                {rightWindow === routes.WEATHER && <WeatherPageContent />}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )) || <Loader />
  );
};
