import { useQuery } from '@tanstack/react-query';

import { REACT_APP_BE_API_STATS_URL } from '../../constants/const';
import { fetchWithMsalHeaders } from '../../helpers/msal';

const url = `${REACT_APP_BE_API_STATS_URL}api/granted-groups`;

export const useQueryGrantedGroups = () =>
  useQuery({
    queryKey: ['granted-groups'],
    queryFn: async () => {
      const [res, msalError] = await fetchWithMsalHeaders(`${url}`);
      if (msalError === null) {
        const userGroups = res.headers.get('user-groups');
        return JSON.parse(userGroups ?? '[]');
      }
      console.error('MSAL Error:', msalError);
    },
  });
