import classnames from 'classnames';

import { ORGAN_COLORS, ORGAN_SHAPES } from '../../constants/const';
import { useElementRef } from '../../hooks/useElementRef';
import { HubStatusType } from '../../types/new';
import { CircleIcon } from '../CircleIcon';

import styles from './HubStatusCard.module.scss';

export const HubStatusCard = ({
  id,
  staffInfo,
  location: { abbreviation, city, state },
}: HubStatusType) => {
  const { ref } = useElementRef<HTMLDivElement>(id);
  return (
    <div
      id={id}
      ref={ref}
      className={classnames(
        styles.hubStatusCard,
        styles.north,
        styles[`${state} ${city}`.replaceAll(/\W/g, '_').toUpperCase()],
      )}
    >
      {staffInfo.map(curStaffInfo => (
        <div
          className={classnames(
            styles.staffIcon,
            styles[curStaffInfo.teamMemberType],
          )}
          key={curStaffInfo.id}
        >
          {curStaffInfo.countsInfo.map(curCountInfo => {
            const organColor = ORGAN_COLORS[curCountInfo.organType];
            const organShape = ORGAN_SHAPES[curCountInfo.organType];
            const availableColorsArray = Array(
              curCountInfo.availableCount,
            ).fill(organColor);
            const assignedColorsArray = Array(curCountInfo.assignedCount).fill(
              '#1D2A37',
            );
            const colorsArray =
              assignedColorsArray.concat(availableColorsArray);

            return colorsArray.length ? (
              <div
                className={classnames(
                  styles.counts,
                  styles[curCountInfo.organType],
                )}
                key={curCountInfo.id}
              >
                {colorsArray.map((curColor, index) => (
                  <div
                    key={`${curStaffInfo.id}-${curCountInfo.id}-${index}`}
                    className={styles[organShape]}
                    style={{ backgroundColor: curColor }}
                  />
                ))}
              </div>
            ) : null;
          })}
          <CircleIcon
            size={30}
            color="#7591AD"
            iconColor="#000000"
            icon={curStaffInfo.teamMemberType}
          />
        </div>
      ))}
      <div className={styles.abbreviation}>{abbreviation}</div>
    </div>
  );
};
