import { PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { allRoutes } from '../../constants/routes';
import { useSsoUserGroupAccess } from '../../hooks/useUserGroupAccess';
import type { SsoUserGroup } from '../../types';
import { Loader } from '../Loader';

interface RouteGuardProps extends PropsWithChildren {
  ssoUserGroups?: SsoUserGroup[];
}

export const RouteGuard = ({
  children,
  ssoUserGroups = [],
}: RouteGuardProps) => {
  const { defaultPage, isDefaultPage, currentAccess, status } =
    useSsoUserGroupAccess(ssoUserGroups);
  const targetPath = localStorage.getItem('TSMED_SSO_STATE_PATH');

  const { pathname } = useLocation();
  const redirectRoute = useMemo(() => {
    if (ssoUserGroups.length) {
      if (targetPath && pathname !== targetPath) {
        return targetPath;
      }

      if (currentAccess && isDefaultPage && pathname !== defaultPage) {
        return defaultPage;
      }

      if (currentAccess && pathname === allRoutes.NO_ACCESS && defaultPage) {
        return defaultPage;
      }

      if (!currentAccess && defaultPage) {
        return defaultPage;
      }

      if (!currentAccess && !defaultPage && pathname !== allRoutes.NO_ACCESS) {
        return allRoutes.NO_ACCESS;
      }
    }
  }, [
    currentAccess,
    defaultPage,
    isDefaultPage,
    pathname,
    ssoUserGroups.length,
    targetPath,
  ]);

  useEffect(() => {
    if (status !== 'pending') {
      localStorage.removeItem('TSMED_SSO_STATE_PATH');
    }
  }, [status]);

  return (
    <>
      {status !== 'pending' ? (
        (redirectRoute && <Navigate to={redirectRoute} />) || children
      ) : (
        <Loader />
      )}
    </>
  );
};
