import classNames from 'classnames';

import { ORGAN_COLORS, ORGAN_SHAPES } from '../../constants/const';

import styles from './Legend.module.scss';

export const Legend = () => {
  const legendItems = [
    { color: ORGAN_COLORS.Heart, shape: ORGAN_SHAPES.Heart, caption: 'Heart' },
    { color: ORGAN_COLORS.Lungs, shape: ORGAN_SHAPES.Lungs, caption: 'Lungs' },
    { color: ORGAN_COLORS.Liver, shape: ORGAN_SHAPES.Liver, caption: 'Liver' },
  ];

  return (
    <div className={styles.legend}>
      {legendItems.map(curLegendItem => (
        <div className={styles.legendItem} key={curLegendItem.caption}>
          <div className={styles.shapeContainer}>
            <div
              className={classNames(styles.shape, styles[curLegendItem.shape])}
              style={{ backgroundColor: curLegendItem.color }}
            ></div>
          </div>
          <div className={styles.caption}>{curLegendItem.caption}</div>
        </div>
      ))}
    </div>
  );
};
