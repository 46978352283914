import classnames from 'classnames';
import { useMemo } from 'react';

import { ORGAN_COLORS, STATUS_COLORS } from '../../../constants/const';
import { getPointPixelsCoordinates } from '../../../helpers/pathGeneration';
import { useElementRef } from '../../../hooks/useElementRef';
import { Case } from '../../../types/new';
import { ICONS } from '../../CircleIcon/CircleIcon';
import styles from '../overlays.module.scss';

interface CaseItemIconsProps extends Case {
  isSetElementRef?: boolean;
}

export const CaseItemIcons = ({
  id,
  label,
  cardStatus,
  team,
  transport,
  donorHospital,
  recipientHospital,
  showTransportsOnMap,
  organ,
  isSetElementRef = false,
}: CaseItemIconsProps) => {
  const { ref } = useElementRef<HTMLDivElement>(`${id}-case-icons`);

  const directionPath = useMemo(() => {
    if (
      donorHospital.lonLat &&
      donorHospital.boundary &&
      recipientHospital.lonLat &&
      recipientHospital.boundary
    ) {
      const [xA] = getPointPixelsCoordinates(
        donorHospital.lonLat,
        donorHospital.boundary,
      );
      const [xB] = getPointPixelsCoordinates(
        recipientHospital.lonLat,
        recipientHospital.boundary,
      );

      return xA < xB ? 'east' : 'west';
    }

    return 'east';
  }, [donorHospital, recipientHospital]);

  const organColor = ORGAN_COLORS[organ.name];

  const mainColor = useMemo(() => {
    const statusColor =
      cardStatus !== 'accept' ? STATUS_COLORS[cardStatus] : null;
    return statusColor || organColor;
  }, [cardStatus, organColor]);

  const TransportIcon = transport ? ICONS[transport] : null;

  return (
    <div ref={isSetElementRef ? ref : null} className={styles.caseItems}>
      {showTransportsOnMap && transport ? (
        <div
          className={classnames(styles.caseTransport, {
            [styles.airNonMao]: transport === 'airNonMao',
            [styles.westDirection]: directionPath === 'west',
          })}
          style={{ color: mainColor }}
        >
          {!!TransportIcon && <TransportIcon width="30px" />}
        </div>
      ) : null}

      <div className={styles.caseLabel} style={{ color: mainColor }}>
        {label}
      </div>
    </div>
  );
};
