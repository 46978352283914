import { Box } from '@mui/material';
import classnames from 'classnames';
import { useState, MouseEvent } from 'react';

import { ICONS } from '../../../components';
import {
  EOrganType,
  EResourceStatus,
  TeamIconType,
  THubsReportStatus,
  TResourceDevice,
  TResourceHuman,
  TResourceType,
  TStaffDetails,
} from '../../../types/new';

import { CellDeviceDrawer } from './CellDeviceDrawer';
import { CellHumanDrawer } from './CellHumanDrawer';
import { CellPopper } from './CellPopper';
import styles from './TableRowItem.module.scss';

// Synced with BE structure
enum organIndexMap {
  Liver,
  Lungs,
  Heart,
}

// Synced with BE structure
enum teamIndexMap {
  Device,
  Surgeon,
  Specialist,
}

const teamIconResourceMap: Record<TeamIconType, TResourceType> = {
  Specialist: 'Specialist',
  Device: 'Device',
  Surgeon: 'Surgeon',
};

interface CellProps {
  day: 'today' | 'tomorrow';
  details: TStaffDetails;
  teamIconName: TeamIconType;
  organ: EOrganType;
  location: THubsReportStatus['location'];
}

const Cell = ({
  details: { resources },
  day,
  organ,
  teamIconName,
  location,
}: CellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMouseOut = () => {
    setAnchorEl(null);
  };
  const handleClick = () => {
    setDialogOpen(!dialogOpen);
  };

  const available = resources.filter(
    r =>
      (teamIconName === 'Device' && r.status === EResourceStatus.Available) ||
      (r as TResourceHuman)[day]?.status === EResourceStatus.Available,
  );
  const availableCount = available.length ?? 0;

  const assigned = (resources as TResourceHuman[]).filter(
    r =>
      (teamIconName === 'Device' && r.status === EResourceStatus.Assigned) ||
      r[day]?.status === EResourceStatus.Assigned,
  );
  const assignedCount = assigned.length ?? 0;
  const returning = (resources as TResourceHuman[])
    .filter(r => r[day] && r[day]?.status === EResourceStatus.OnRest)
    ?.sort((a, b) =>
      (a[day]?.endUTCDateTime ?? '') < (b[day]?.endUTCDateTime ?? '') ? -1 : 1,
    );

  const returningCount = returning.length ?? 0;

  return (
    <>
      <Box
        className={styles.cell}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <div
          className={classnames(styles.amount, {
            [styles.unavailable]: !availableCount && assignedCount,
          })}
        >
          {availableCount || assignedCount || '-'}
        </div>
        <div className={styles.time}>
          {returning?.[0]?.[day]?.endTime ?? '-'}
        </div>
      </Box>
      <CellPopper
        anchorEl={anchorEl}
        assignedCount={assignedCount}
        availableCount={availableCount}
        returningCount={returningCount}
        day={day}
        icon={ICONS[teamIconName]}
        primaryText={`${EOrganType[organ]} ${teamIconResourceMap[teamIconName]}`}
        secondaryText={`${location.city}, ${location.timeZone}`}
      />
      {teamIconName === 'Device' ? (
        <CellDeviceDrawer
          open={dialogOpen}
          day={day}
          organ={organ}
          secondaryText={`${location.city}, ${location.timeZone}`}
          resources={resources as TResourceDevice[]}
          onClose={handleClick}
        />
      ) : (
        <CellHumanDrawer
          open={dialogOpen}
          day={day}
          organ={organ}
          secondaryText={`${location.city}, ${location.timeZone}`}
          available={available}
          assigned={assigned}
          returning={returning}
          onClose={handleClick}
          title={teamIconName === 'Specialist' ? 'Specialists' : 'Surgeons'}
        />
      )}
    </>
  );
};

interface DayGroupProps {
  row: THubsReportStatus;
  day: 'today' | 'tomorrow';
  organ: EOrganType;
}

const DayGroup = ({ day, row, organ }: DayGroupProps) => (
  <div className={classnames(styles['day-group'], styles.today)}>
    <Cell
      details={
        row.staffInfo[teamIndexMap.Specialist].details[organIndexMap[organ]]
      }
      location={row.location}
      organ={organ}
      day={day}
      teamIconName={TeamIconType.Specialist}
    />
    <Cell
      details={
        row.staffInfo[teamIndexMap.Surgeon].details[organIndexMap[organ]]
      }
      day={day}
      location={row.location}
      organ={organ}
      teamIconName={TeamIconType.Surgeon}
    />
    <Cell
      details={row.staffInfo[teamIndexMap.Device].details[organIndexMap[organ]]}
      day={day}
      location={row.location}
      organ={organ}
      teamIconName={TeamIconType.Device}
    />
  </div>
);

export interface TableRowItemProps {
  row: THubsReportStatus;
  isEven: boolean;
}

export const TableRowItem = ({ row, isEven }: TableRowItemProps) => {
  const { timeZone, city } = row.location;
  return (
    <li
      className={classnames(styles.row, {
        [styles.even]: isEven,
        [styles.odd]: !isEven,
      })}
    >
      <div className={styles['hub-cell']}>
        <div className={styles.city}>{city}</div>
        <div className={styles.abbreviation}>{timeZone}</div>
      </div>
      <div className={styles['day-groups']}>
        <DayGroup day="today" organ={EOrganType.Liver} row={row} />
        <DayGroup day="tomorrow" organ={EOrganType.Liver} row={row} />
        <DayGroup day="today" organ={EOrganType.Heart} row={row} />
        <DayGroup day="tomorrow" organ={EOrganType.Heart} row={row} />
        <DayGroup day="today" organ={EOrganType.Lungs} row={row} />
        <DayGroup day="tomorrow" organ={EOrganType.Lungs} row={row} />
      </div>
    </li>
  );
};
