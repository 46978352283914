import classnames from 'classnames';
import { FunctionComponent, SVGProps, useMemo } from 'react';

import { ReactComponent as CarSVG } from '../../assets/car.svg';
import { ReactComponent as CarPlaneSVG } from '../../assets/carPlane.svg';
import { ReactComponent as CaseSVG } from '../../assets/case.svg';
import { ReactComponent as GearSVG } from '../../assets/gear.svg';
import { ReactComponent as HeartSVG } from '../../assets/heart.svg';
import { ReactComponent as LiverSVG } from '../../assets/liver.svg';
import { ReactComponent as LungSVG } from '../../assets/lung.svg';
import { ReactComponent as NurseSVG } from '../../assets/nurse.svg';
import { ReactComponent as PlaneSVG } from '../../assets/plane.svg';
import { ReactComponent as ScalpelSVG } from '../../assets/scalpel.svg';
import { EOrganType, TeamIconType, TransportIconType } from '../../types/new';

import styles from './CircleIcon.module.scss';

interface Props {
  icon: EOrganType | TeamIconType | TransportIconType;
  size?: number;
  color?: string;
  iconColor?: string;
  pinned?: boolean;
  shadow?: boolean;
  shape?: string;
  borderStyle?: string;
}

export const ICONS: Record<
  EOrganType | TeamIconType | TransportIconType,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  [EOrganType.Heart]: HeartSVG,
  [EOrganType.Liver]: LiverSVG,
  [EOrganType.Lungs]: LungSVG,
  [TeamIconType.Specialist]: NurseSVG,
  [TeamIconType.Device]: CaseSVG,
  [TeamIconType.Surgeon]: ScalpelSVG,
  [TransportIconType.groundOnly]: CarSVG,
  [TransportIconType.airNonMao]: CarPlaneSVG,
  [TransportIconType.airMao]: PlaneSVG,
};

const getSize = (size: number, shape: string, hasBorder: boolean): number => {
  const currentSize = hasBorder ? size + 4 : size;
  if (shape === 'circle') {
    return currentSize * 1.154;
  }
  if (shape === 'rhombus') {
    return currentSize * 0.9246;
  }

  return currentSize;
};

export const CircleIcon = ({
  icon,
  size = 30,
  color = '#FF975D',
  iconColor = '#000000',
  pinned = false,
  shadow = false,
  shape = 'circle',
  borderStyle = '',
}: Props) => {
  const Icon = useMemo(() => ICONS[icon], [icon]);

  const wrapperSize = getSize(size, 'circle', !!borderStyle);
  const shapeSize = getSize(size, shape, !!borderStyle);

  const iconSize = size * 0.65;
  const borderWidth = Math.floor((shapeSize / 5) * 2);

  return (
    <div
      className={styles.circleIcon}
      style={{
        width: `${wrapperSize}px`,
        height: `${wrapperSize}px`,
      }}
    >
      <div
        className={classnames(styles.circleIcon, styles[shape], {
          [styles.shadow]: shadow,
        })}
        style={{
          width: `${shapeSize}px`,
          height: `${shapeSize}px`,
          backgroundColor: color,
          color: iconColor,
          border: borderStyle,
        }}
      >
        {pinned && (
          <div
            className={styles.pin}
            style={{
              bottom: `${-Math.floor(shapeSize / 3)}px`,
              borderColor: `${color} transparent transparent transparent`,
              borderWidth: `${borderWidth / 2}px`,
            }}
          />
        )}
        {Icon ? (
          <Icon width={iconSize} height={iconSize} />
        ) : (
          <GearSVG width={iconSize} height={iconSize} />
        )}
      </div>
    </div>
  );
};
