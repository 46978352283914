import { inflate } from 'pako';

import { REACT_APP_ENV } from '../../constants/const';

const fetcher = async (url: string) => {
  try {
    const apiResponse = await fetch(url);
    if (url.includes('.gz')) {
      let buffer = await apiResponse.arrayBuffer();
      buffer = inflate(buffer);
      const raw = new TextDecoder().decode(buffer);
      return `${raw}`;
    }
    return await apiResponse.json();
  } catch (err) {
    console.error(err);
    throw err;
  }
};
const localFetcher = async (url: string) => {
  const localUrl = `/test_data/${url
    .replace(/.*\//, '')
    .replace(/\.gz.*$/, '')}`;
  const apiResponse = await fetch(localUrl);
  if (url.includes('.gz')) {
    const raw = await apiResponse.text();
    return `${raw}`;
  }
  return await apiResponse.json();
};

export const aviationWeatherFetch =
  REACT_APP_ENV === 'local' ? localFetcher : fetcher;
