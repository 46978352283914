import classnames from 'classnames';

import { ReactComponent as GearSVG } from '../../assets/gear.svg';
import {
  ORGAN_COLORS,
  ORGAN_PROGRESS_COLORS,
  STATUS_COLORS,
} from '../../constants/const';
import { Case, ENOPCaseStatus } from '../../types/new';
import { ICONS } from '../CircleIcon/CircleIcon';

import styles from './TripCard.module.scss';

const LABELS_MARGIN = 6;

interface Props {
  theCase: Case;
  additionalClassName?: string;
}

const caseStatusMap: Record<ENOPCaseStatus, string> = {
  [ENOPCaseStatus.None]: ENOPCaseStatus.None,
  [ENOPCaseStatus.Draft]: ENOPCaseStatus.Draft,
  [ENOPCaseStatus.TXCenterDraft]: ENOPCaseStatus.TXCenterDraft,
  [ENOPCaseStatus.ClinicalScreening]: 'Clinical Screening',
  [ENOPCaseStatus.Declined]: 'Case Declined',
  [ENOPCaseStatus.PendingLogistics]: 'Pending Logistics',
  [ENOPCaseStatus.ResourceDeployed]: 'Resources Deployed',
  [ENOPCaseStatus.ResourcesBooked]: 'Resources Assigned',
  [ENOPCaseStatus.AtDonorHospital]: 'At Donor Hospital',
  [ENOPCaseStatus.OrganOnOCS]: 'On OCS',
  [ENOPCaseStatus.DeployedToTXCenter]: 'In Transit to TX Center',
  [ENOPCaseStatus.AtTXCenter]: 'At TX Center',
  [ENOPCaseStatus.OrganOffOCS]: 'Off OCS',
  [ENOPCaseStatus.ServiceCompleted]: 'Service Completed',
  [ENOPCaseStatus.ServiceDeclined]: 'Transplant not Completed',
  [ENOPCaseStatus.ReturningToBase]: 'Returning to Base',
  [ENOPCaseStatus.Finished]: ENOPCaseStatus.Finished,
  [ENOPCaseStatus.Closed]: 'Case Closed',
  [ENOPCaseStatus.Accepted]: 'Case Accepted',
  [ENOPCaseStatus.New]: ENOPCaseStatus.New,
  [ENOPCaseStatus.OrTimeConfirmed]: ENOPCaseStatus.OrTimeConfirmed,
  [ENOPCaseStatus.QuoteReceived]: ENOPCaseStatus.QuoteReceived,
  [ENOPCaseStatus.QuoteSent]: ENOPCaseStatus.QuoteSent,
  [ENOPCaseStatus.QuoteRejected]: ENOPCaseStatus.QuoteRejected,
  [ENOPCaseStatus.QuoteApproved]: ENOPCaseStatus.QuoteApproved,
  [ENOPCaseStatus.Deleted]: ENOPCaseStatus.Deleted,
};

// eslint-disable-next-line complexity
export const TripCard = ({ theCase, additionalClassName = '' }: Props) => {
  const {
    label,
    donorType,
    donorORTimeString,
    etaString,
    points,
    organ,
    pathLabels,
    transport,
    progress,
    caseTimerEndString,
    showDonorOr,
    showTimer,
    showReturnToBaseState,
    status,
    cardStatus,
    hasErrors,
    showOrganOnCard,
    showOrganColor,
    showTransportOnCard,
  } = theCase;

  const organColor = ORGAN_COLORS[organ.name];
  const mainColor = STATUS_COLORS[cardStatus] || organColor;
  const progressColor =
    STATUS_COLORS[cardStatus] || ORGAN_PROGRESS_COLORS[organ.name];
  const progressMainColor = cardStatus === 'accept' ? '#587E3A' : progressColor;

  const TransportIcon = transport ? ICONS[transport] : null;
  const OrganIcon = ICONS[organ.name] ? ICONS[organ.name] : GearSVG;

  const transportProgress = progress < 96 ? progress : 96;
  const organIconMainColor = showOrganColor ? organColor : '#ffffff';
  const organIconColor = showReturnToBaseState ? '#7F8083' : organIconMainColor;
  const mainTextColor = !showReturnToBaseState ? mainColor : '#EFECEA';

  return (
    <div
      className={classnames(
        styles.tripCard,
        styles[cardStatus],
        styles[additionalClassName],
      )}
      style={{ borderColor: STATUS_COLORS[cardStatus] || '#1D2A37' }}
    >
      <div className={styles.tripCardHeader}>
        <div className={styles.tripCardId}>
          {hasErrors ? (
            <div className={styles.errorIcon}>
              <GearSVG />
            </div>
          ) : null}
          <span className={styles.unosId} style={{ color: mainTextColor }}>
            {label}
          </span>
          <span className={styles.donorType} style={{ color: mainTextColor }}>
            {donorType}
          </span>
        </div>
        <div className={styles.status}>
          <span style={{ color: mainTextColor }}>{caseStatusMap[status]}</span>
        </div>
      </div>
      <div className={styles.progressBlock}>
        <div className={styles.tripProgress}>
          <div className={styles.progressLine} />
          <div
            className={styles.activeProgressLine}
            style={{
              width: `${progress}%`,
              backgroundColor: !showReturnToBaseState
                ? progressMainColor
                : '#7F8083B2',
            }}
          />
          <div className={styles.activeProgress}>
            {showOrganOnCard && (
              <div className={styles.items}>
                <div
                  key={organ.id}
                  className={classnames(styles.organIcon, {
                    [styles.organIconEnd]: organ.startPoint === 100,
                  })}
                  style={{
                    left: `${organ.startPoint}%`,
                    color: organIconColor,
                  }}
                >
                  <OrganIcon />
                </div>
              </div>
            )}
            {showTransportOnCard && !!transport && !!TransportIcon && (
              <div className={styles.transports}>
                <div
                  className={styles.transportItem}
                  style={{
                    left: `${transportProgress}%`,
                    transform: `translate(${progress < 3 ? -15 : -50}%)`,
                  }}
                >
                  <TransportIcon />
                </div>
              </div>
            )}
            <div className={styles.checkpoints}>
              {points.map(point =>
                !point.hidden ? (
                  <div
                    key={point.id}
                    className={styles.circle}
                    style={{
                      left: `${point.point}%`,
                    }}
                  />
                ) : null,
              )}
            </div>
          </div>
        </div>
        <div className={styles.pathLabels}>
          {pathLabels.map((label, index) => {
            const isStart = !label.startPoint;
            const isDonorOr = index === 1;
            const isTmdxAviationCase = isDonorOr && label.startPoint === 10;
            const donorOrMaxWidth = isTmdxAviationCase
              ? pathLabels[2].startPoint - label.startPoint
              : pathLabels[2].startPoint - label.startPoint / 2;
            const isEnd = label.startPoint === 100;
            const names = label.name.split(', ');
            const isLeftTitle = isStart || isEnd || isTmdxAviationCase;

            return (
              <div
                key={label.id}
                className={styles.pathLabelText}
                style={{
                  color: !label.isActive ? '#87888B' : '#DDDDDD',
                  maxWidth: isDonorOr
                    ? `${donorOrMaxWidth - LABELS_MARGIN}%`
                    : 'auto',
                  width: 'auto',
                  left: isEnd ? 'auto' : `${label.startPoint}%`,
                  transform: `translate(${isLeftTitle ? 0 : '-50'}%)`,
                  right: isEnd ? '0' : `auto`,
                }}
              >
                {names.map((i, index) => (
                  <span
                    key={`${label.id}-${index}`}
                    className={styles.text}
                    style={{
                      minWidth: names.length > 1 && !index ? 0 : 'auto',
                      flexShrink: names.length > 1 && index ? '0' : '1',
                    }}
                  >
                    {index ? `, ${i}` : i}
                  </span>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.tripAircrafts}>
        {points.map((point, idx) => {
          const isActualPoint =
            progress >= point.point &&
            progress < (points[idx + 1]?.point || 100);
          const isPastPoint = progress >= (points[idx + 1]?.point || 100);
          return point.type === 'airMao' ? (
            <div
              key={point.id}
              className={styles.flightId}
              style={{
                backgroundColor: isActualPoint ? `${mainColor}40` : '#000000',
                color: isActualPoint ? mainColor : '#A3A7AB',
                ...((isPastPoint && {
                  opacity: 0.5,
                }) ||
                  {}),
              }}
            >
              {point.subId}
            </div>
          ) : point.type === 'donorOR' ? (
            <div
              key={point.id}
              style={{
                flexGrow: 1,
              }}
            >
              {point.subId}
            </div>
          ) : null;
        })}
      </div>
      <div className={styles.tripBottom}>
        <div className={styles.tripTimer}>
          {showDonorOr && (
            <span className={classnames(styles.tripTime, styles.tripCardEta)}>
              {donorORTimeString}
            </span>
          )}
          {showTimer && (
            <span
              className={styles.tripTime}
              style={{
                color: STATUS_COLORS[cardStatus] || '#EFECEA',
              }}
            >
              {caseTimerEndString}
            </span>
          )}
        </div>
        {!showReturnToBaseState && (
          <div
            className={classnames(styles.tripTime, styles.tripCardEta)}
            style={{
              color: STATUS_COLORS[cardStatus] || '#EFECEA',
              opacity: STATUS_COLORS[cardStatus] ? 1 : 0.5,
            }}
          >
            {`ETA ${etaString}`}
          </div>
        )}
      </div>
    </div>
  );
};
