import classNames from 'classnames';

import { Case } from '../../types/new';
import { TripCard } from '../TripCard';

import styles from './TripsColumn.module.scss';

interface Props {
  cases: Case[];
  additionalClassName?: string;
}

export const TripsColumn = ({ cases, additionalClassName = '' }: Props) => (
  <div className={classNames(styles.trips, styles[additionalClassName])}>
    {cases.map(curCase => (
      <TripCard
        additionalClassName={additionalClassName}
        theCase={curCase}
        key={curCase.label}
      />
    ))}
  </div>
);
