import { useQuery } from '@tanstack/react-query';

import { REACT_APP_BE_API_STATS_URL } from '../../constants/const';
import { fetchWithMsalHeaders } from '../../helpers/msal';
import { StatsMTBAAircraft, StatsMTBASummary } from '../../types';

import { StatsQueryParams } from './types';

const url = `${REACT_APP_BE_API_STATS_URL}api/stats/mtba`;
export const useQueryStatsMTBA = ({
  dateTo,
  fromUnitType,
  fromValue,
  aircrafts = [],
}: StatsQueryParams) => {
  const enabled = ![fromUnitType, fromValue, dateTo].some(v => v === undefined);
  const dateToString = dateTo.toISOString();
  return useQuery({
    queryKey: ['StatsMTBA', fromValue, fromUnitType, dateToString, aircrafts],
    queryFn: async () => {
      const [res, msalError] = await fetchWithMsalHeaders(
        `${url}?${new URLSearchParams({
          dateTo: dateToString,
          aircrafts: aircrafts.toString(),
          fromValue: `${fromValue}`,
          fromUnitType,
        })}`,
      );

      if (msalError === null) {
        return (await res.json()) as {
          perDate: Record<string, StatsMTBASummary>;
          summary: StatsMTBASummary;
          perAircraft: Record<string, StatsMTBAAircraft>;
        };
      }
      console.error('MSAL Error:', msalError);
    },
    select: data => data,
    enabled,
  });
};
