import { Box, styled } from '@mui/material';
import classnames from 'classnames';
import { useContext, useEffect, useState } from 'react';

import { transformAircrafts } from '../../adapters/myAirOps/transformAircraftStatuses';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { AircraftsMapOverlay } from '../../components/overlays';
import { Statistics } from '../../components/Statistics';
import { TechnicalTimers } from '../../components/TechnicalTimers';
import { UsaMap } from '../../components/UsaMap/UsaMap';
import { MapContext } from '../../contexts/MapContext';
import { currentTimeInTimezone } from '../../helpers/timezoneHelper';
import { TAircraftList } from '../../types/new';

const STATUS_TITLES: Record<string, string> = {
  Available: 'Available',
  OnTheCase: 'On the Case',
  ReturnToBase: 'Returning to base',
  NA: 'N/A',
  Maintenance: 'Maintenance',
};

export const statusList: Record<string, number> = {
  Available: 0,
  OnTheCase: 0,
  ReturnToBase: 0,
  NA: 0,
  Maintenance: 0,
};

const PREFIX = 'TSMED-AircraftCardMapPageContent';

const aircraftMapPageContentClasses = {
  mapLayout: `${PREFIX}-mapLayout`,
  mapContainer: `${PREFIX}-mapContainer`,
  title: `${PREFIX}-title`,
  infoBlock: `${PREFIX}-infoBlock`,
  statusList: `${PREFIX}-statusList`,
  status: `${PREFIX}-status`,
  infoText: `${PREFIX}-infoText`,
  legend: `${PREFIX}-legend`,
  legendItem: `${PREFIX}-legendItem`,
  legendItemBlue: `${PREFIX}-legendItemBlue`,
  legendItemWhite: `${PREFIX}-legendItemWhite`,
  legendItemMint: `${PREFIX}-legendItemMint`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { palette } }) => ({
  width: 1920,
  height: 1080,
  padding: 20,
  boxSizing: 'border-box',
  position: 'relative',
  overflow: 'hidden',

  [`.${aircraftMapPageContentClasses.mapLayout}`]: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    transform: 'translateX(-75px)',
  },

  [`.${aircraftMapPageContentClasses.mapContainer}`]: {
    width: '95%',
    height: 'auto',
  },

  [`.${aircraftMapPageContentClasses.title}`]: {
    position: 'absolute',
    zIndex: 10,
    color: '#dddddd',
    fontSize: '24px',
    fontWeight: 500,
    margin: 0,
    padding: 0,
    top: 20,
    left: 20,
  },

  [`.${aircraftMapPageContentClasses.infoBlock}`]: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },

  [`.${aircraftMapPageContentClasses.statusList}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
  },

  [`.${aircraftMapPageContentClasses.status}`]: {
    fontSize: '24px',
    fontWeight: 500,
    color: '#dddddd',
  },

  [`.${aircraftMapPageContentClasses.infoText}`]: {
    color: '#7f8083',
    fontSize: '24px',
    margin: 0,
    padding: 0,
  },

  [`.${aircraftMapPageContentClasses.legend}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,

    [`.${aircraftMapPageContentClasses.legendItem}`]: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      color: '#7f8083',
      fontWeight: 500,

      ['& > div']: {
        width: 50,
        height: 31,
        borderRadius: '4px 0 4px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        backgroundColor: '#7f808326',
      },

      ['& > span']: {
        fontSize: '24px',
        color: '#7f8083',
      },

      [`&.${aircraftMapPageContentClasses.legendItemWhite}`]: {
        color: 'white',

        ['& > div']: {
          backgroundColor: '#ffffff26',
        },
      },

      [`&.${aircraftMapPageContentClasses.legendItemMint}`]: {
        color: '#8bd8ae',

        ['& > div']: {
          backgroundColor: '#8bd8ae26',
        },
      },

      [`&.${aircraftMapPageContentClasses.legendItemBlue}`]: {
        color: '#7591ad',

        ['& > div']: {
          backgroundColor: '#7591ad26',
        },
      },
    },
  },
}));

export const AircraftsMapPageContent = () => {
  const { allData, lastUpdateTime } = useContext(MapContext);
  const [aircrafts, setAircrafts] = useState<TAircraftList>([]);

  useEffect(() => {
    if (allData) {
      setAircrafts(
        transformAircrafts(allData.aircrafts.list, allData.activeCases),
      );
    }
  }, [allData]);

  return (
    (allData && lastUpdateTime && (
      <Root>
        <h1 className={aircraftMapPageContentClasses.title}>
          JET Fleet live tracking
        </h1>
        <div className={aircraftMapPageContentClasses.mapLayout}>
          <div className={aircraftMapPageContentClasses.mapContainer}>
            <UsaMap />
          </div>
          <AircraftsMapOverlay aircrafts={aircrafts} />
        </div>
        {!!Object.keys(allData.statistics).length && (
          <Statistics statistics={allData.statistics} />
        )}
        <div className={aircraftMapPageContentClasses.infoBlock}>
          <div className={aircraftMapPageContentClasses.statusList}>
            {Object.keys(allData.aircrafts.statuses).map(i => (
              <span key={i} className={aircraftMapPageContentClasses.status}>
                {STATUS_TITLES[i]}: {allData.aircrafts.statuses[i]}
              </span>
            ))}
          </div>
          <div className={aircraftMapPageContentClasses.legend}>
            <p className={aircraftMapPageContentClasses.infoText}>
              Last Update:{' '}
              {currentTimeInTimezone(lastUpdateTime, 'America/New_York')} ET
            </p>
            <div
              className={classnames(
                aircraftMapPageContentClasses.legendItem,
                aircraftMapPageContentClasses.legendItemWhite,
              )}
            >
              <div>8+</div>
              <span>h</span>
            </div>
            <div
              className={classnames(
                aircraftMapPageContentClasses.legendItem,
                aircraftMapPageContentClasses.legendItemMint,
              )}
            >
              <div>6-8</div>
              <span>h</span>
            </div>
            <div
              className={classnames(
                aircraftMapPageContentClasses.legendItem,
                aircraftMapPageContentClasses.legendItemBlue,
              )}
            >
              <div>{`<6`}</div>
              <span>h</span>
            </div>
            <div className={aircraftMapPageContentClasses.legendItem}>
              <div>X</div>
              <span>N/A</span>
            </div>
          </div>
        </div>
      </Root>
    )) || <Loader />
  );
};

export const AircraftsMapPage = () => (
  <Layout>
    <AircraftsMapPageContent />
    <TechnicalTimers />
  </Layout>
);
