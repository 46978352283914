import { useContext, useEffect, useMemo, useState } from 'react';

import {
  MAP_OVERLAY_VIEW_HEIGHT,
  MAP_OVERLAY_VIEW_WIDTH,
  MAP_PAGE_WIDTH,
} from '../../../constants/const';
import { usaStatesWestern } from '../../../constants/usa';
import { DomElementsContext } from '../../../contexts/DomElementsContext';
import {
  getNewCaseLine,
  getPointPixelsCoordinates,
} from '../../../helpers/pathGeneration';
import { useSvgClientRect } from '../../../hooks/useSvgClientRect';
import { TPoint } from '../../../types';
import styles from '../overlays.module.scss';

interface Props {
  id: string;
  state: string;
  boundary: string | null;
  point: TPoint | null;
}

export const NewAlertLine = ({ id, state, point, boundary }: Props) => {
  const [alertCardTop, setAlertCartTop] = useState(0);
  const [alertCardHeight, setAlertCartHeight] = useState(0);
  const [showLine, setShowLine] = useState(true);
  const { getElement } = useContext(DomElementsContext);
  const { width: clientWidth, height: clientHeight } =
    useSvgClientRect('mainOverlaySVG');

  const newAlertCard = useMemo<HTMLDivElement | null>(
    () => getElement(id),
    [id, getElement],
  );

  const isWestState = useMemo(() => usaStatesWestern.includes(state), [state]);

  const listElement = useMemo(
    () => getElement(isWestState ? 'west-alerts' : 'east-alerts'),
    [isWestState, getElement],
  );

  useEffect(() => {
    if (newAlertCard) {
      setAlertCartTop(newAlertCard.offsetTop);
      setAlertCartHeight(newAlertCard.offsetHeight);
    }
  }, [newAlertCard]);

  useEffect(() => {
    if (listElement && newAlertCard) {
      const observer = new MutationObserver(() => {
        const computedStyles = getComputedStyle(newAlertCard);

        if (computedStyles.display === 'none') {
          setShowLine(false);
        } else {
          setAlertCartTop(newAlertCard.offsetTop);
          setShowLine(true);
        }
      });

      observer.observe(listElement as Node, {
        subtree: true,
        attributes: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [listElement, newAlertCard]);

  const [line, position] = useMemo<[string | null, TPoint | null]>(() => {
    if (!point || !boundary) {
      return [null, null];
    }

    const position = getPointPixelsCoordinates(point, boundary);

    if (!showLine || !alertCardTop || !clientHeight || !clientWidth) {
      return [null, position];
    }

    const lineTopOffset = isWestState ? 0 : alertCardHeight;
    const top = isWestState ? alertCardTop + 1 : alertCardTop - 1;
    const mainOffsetWidth =
      document.getElementById('main-map-page')?.offsetWidth || MAP_PAGE_WIDTH;
    const cardPosition =
      ((top + lineTopOffset) / clientHeight) * MAP_OVERLAY_VIEW_HEIGHT;
    const offset =
      ((mainOffsetWidth * 0.03) / clientWidth) * MAP_OVERLAY_VIEW_WIDTH;
    const line = getNewCaseLine(position, cardPosition, offset, isWestState);

    return [line, position];
  }, [
    boundary,
    showLine,
    isWestState,
    alertCardHeight,
    alertCardTop,
    point,
    clientHeight,
    clientWidth,
  ]);

  return (
    <>
      {line ? (
        <path
          id={`${id}-line`}
          strokeWidth={1}
          stroke={
            isWestState
              ? 'url(#westAlertLineGradient)'
              : 'url(#eastAlertLineGradient)'
          }
          d={line}
        />
      ) : null}
      {position ? (
        <circle
          id={`${id}-point`}
          cx={position[0]}
          cy={position[1]}
          r="1.5"
          className={styles.directionPoint}
          stroke="#FF975D"
        />
      ) : null}
    </>
  );
};
